<template>
  <div class="bg-white p-2 rounded-md mt-2">
    <DataTable :value="solicitudes.rows"
               class="p-datatable-customers p-datatable-sm text-sm"
               showGridlines
               dataKey="id"
               v-model:filters="filters1"
               filterDisplay="menu"
               responsiveLayout="scroll"
               removableSort
    >
      <template #empty>
        No hay datos para mostrar
      </template>
      <template #header>
        <div class="flex justify-end">
          <div class="flex justify-between gap-4">
            <div class="flex">
              <Button type="button"
                      icon="pi pi-filter-slash text-sm"
                      label="Limpiar filtros"
                      class="p-button-outlined text-sm font-medium"
                      @click="clearFilter1()"
              />
            </div>
          </div>
        </div>
      </template>

      <Column headerStyle="width: 3rem" style="text-align: center; justify-content: center;" header="Id">
        <template #body="{data}">
          <p class="text-xs">
            {{ data.id }}
          </p>
        </template>
      </Column>

      <Column field="tipo" header="Tipo" style="min-width:10rem" :showFilterMatchModes="false">
        <template #body="{data}">
          <span class="font-bold text-blue-800 hover:underline cursor-pointer text-xs"
                @click="ver(data)">{{ $h.capitalize(data.tipoName) }}</span>
        </template>
        <template #filter="{filterModel}">
          <div class="mb-3 font-bold">Seleccione</div>
          <Dropdown v-model="filterModel.value" :options="tipoSolicitudes" optionLabel="nombre" optionValue="id"
                    placeholder="Seleccione" :filter="true"/>
        </template>
      </Column>

      <Column field="solicitud" header="Solicitud" style="min-width:10rem">
        <template #body="{data}">
          <p class="text-xs">
            {{ data.solicitud }}
          </p>
        </template>
      </Column>

      <Column field="solicitudId" header="Solicitado A">
        <template #body="{data}">
          <p class="text-xs">
            {{ data.solicitado ? data.solicitado.fullName : '' }}
          </p>
        </template>
      </Column>

      <Column field="createdAt" header="Fecha de vencimiento" style="min-width:10rem">
        <template #body="{data}">
          <p class="text-xs">
            {{ $h.formatDate(data.fechaVencimiento) }}
          </p>
        </template>
      </Column>

      <Column field="createdBy" header="Creado Por" style="min-width:10rem" :showFilterMatchModes="false">
        <template #body="{data}">
          <p class="text-xs">
            {{ data.solicitante.fullName }}
          </p>
        </template>
        <template #filter="{filterModel}">
          <div class="mb-3 font-bold">Seleccione</div>
          <Dropdown v-model="filterModel.value" :options="usuarios" optionLabel="fullName" optionValue="id"
                    placeholder="Cualquiera" :filter="true"/>
        </template>
      </Column>

      <Column field="respuesta" header="Respuesta">
        <template #body="{data}">
          <template v-if="data.respuesta">
            <span class="text-xs">{{ data.respuesta }}</span>
          </template>
          <template v-else>
            No tiene
          </template>
        </template>
      </Column>

      <Column header="Estado"
              style="min-width:10rem; text-align: center"
      >
        <template #body="{data}">
          <Button
            type="button"
            v-if="!data.respuesta"
            class="font-medium p-1.5 text-white w-3/4 text-center rounded-md border-0"
            :class="indicadorEstadoColor(data.fechaVencimiento, data.respuesta)"
            :label="indicadorEstadoNombre(data.fechaVencimiento, data.respuesta)"
            v-tooltip.bottom="{value:
          `${dayjs(data.fechaVencimiento) > dayjs() ? 'Vence' : 'Vencido'} ${dayjs(data.fechaVencimiento).locale('es').fromNow()}
          `
          }"/>
          <Button
            type="button"
            label="Respondida"
            v-else
            class="font-medium p-1.5 text-white w-3/4 text-center rounded-md border-0"
            v-tooltip.bottom="{value: 'Respondida' }"
          />
        </template>
      </Column>

      <Column header="Acciones" style="width: 10rem">
        <template #body="{data}">
          <div class="flex gap-4 justify-center">
            <template v-if="!data.respuesta || $can('pharmasan.administrativa.juridica.solicitudes.access')">
              <Button icon="pi pi-reply" class="p-button-rounded p-button-help" @click="respuesta(data.id)"
                      v-tooltip.top="{value: 'Responder'}"/>
              <!-- <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning" @click="$router.push({ name: 'procesos.judiciales.solicitudes.crear', params: { id: data.id } })" /> -->
              <!-- <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="remove(data)" /> -->
            </template>
            <div v-else>
              <Button icon="pi pi-file-pdf" @click="openModal(data.id)" class="p-button-rounded p-button-info"/>
              <!-- <Button @click="$router.push({ name: 'procesos.judiciales.solicitudes.ver-respuesta', params: { id: data.id } })" label="Respuesta" icon="pi pi-eye" class="p-button-rounded p-button-primary" /> -->
            </div>
          </div>
        </template>
      </Column>
    </DataTable>
    <Paginator v-model:first="offset"
               :rows="limit"
               :totalRecords="totalItemsCount"
               :rowsPerPageOptions="[2,10,20,30]"
               @page="onPage($event)"
    />
  </div>

  <Dialog header="Adjuntos" v-model:visible="displayModal" :breakpoints="{'960px': '75vw', '640px': '90vw'}"
          :style="{width: '30vw'}" :modal="true">
    <div v-for="(adjunto, i) in adjuntos" :key="i">
      <div class="flex justify-between">
        <div>
          <p>
            {{ adjunto.fileName }}
          </p>
        </div>
        <div>
          <button @click="verPdf(adjunto.urlPath)">
            <i class="pi pi-eye text-blue-600"></i>
          </button>
        </div>
      </div>
    </div>
    <template #footer>
      <Button label="Agregar Soporte" class="p-button-warning" @click="addSoporte" />
      <Button label="OK" @click="closeModal" autofocus/>
    </template>
  </Dialog>
  <Dialog header="Agregar soportes" v-model:visible="modalAgregarSoportesVisible" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '25vw'}" :modal="true">
    <div>
      <div>
        <label class="text-sm" for="titulo">Titulo</label>
        <input type="text" v-model="title" id="titulo" placeholder="Escriba aqui el titulo del documento"
               class="text-sm mt-2 block placeholder-gray-300 p-2  w-full text-sm text-gray-900 rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>
      <div class="my-2">
        <label class="text-sm">Adjunte el documento</label>
        <div class="mt-2">
          <button class="w-full">
            <FilePond ref="pond"/>
          </button>
        </div>
      </div>
    </div>
    <template class="text-center" #footer>
      <Button label="Guardar" class="w-full" @click="closeAgregarSoportes" autofocus />
    </template>
  </Dialog>
</template>

<script>
import solicitudesStore from '../../../../../store/solicitudes.store'
import { computed, onMounted, ref, watch } from 'vue'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import Swal from 'sweetalert2'
import { useRouter, useRoute } from 'vue-router'
import PdfService from '@/apps/pharmasan/juridica/procesosJuridicos/services/pdf.service'
import UserSerivce from '@/apps/pharmasan/juridica/procesosJuridicos/services/usuarios.service'
import derechosPeticionStore from '../../../../../store/derechosPeticion.store'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import FilePond from '../../../../uploadFilePoundNew/index.vue'
import FilePondStore from '@/apps/pharmasan/juridica/procesosJuridicos/store/filepond.store'

export default {
  name: 'TablaSolicitudes',
  components: {
    FilePond
  },
  setup () {
    dayjs.extend(duration)
    dayjs.extend(relativeTime)
    dayjs().locale('es')
    const router = useRouter()
    const route = useRoute()
    const _PdfService = new PdfService()
    const _UserService = new UserSerivce()
    const solicitudes = computed(() => solicitudesStore.getters.solicitudes)
    const tipoSolicitudes = computed(() => solicitudesStore.getters.solicitudTipo)
    const usuarios = ref([])
    const modalAgregarSoportesVisible = ref(false)
    const title = ref('')
    const pond = ref()
    const idSolicitud = ref(null)
    const paths = ref([])
    const adjunto = computed(
      () => FilePondStore.getters.adjunto
    )
    const paginator = ref({
      offset: 0,
      page: 0,
      limit: 10
    })
    const offset = ref(0)
    const page = ref(0)
    const limit = ref(10)
    const displayModal = ref(false)
    const adjuntos = ref([])
    // computed
    const params = computed(() => {
      return {
        offset: offset.value,
        page: page.value,
        limit: limit.value,
        filters: filters1.value
      }
    })
    const totalItemsCount = computed(
      () => solicitudesStore.getters.solicitudes.count
    )
    const solicitud = computed(() => solicitudesStore.getters.solicitud)

    const filters1 = ref({
      id: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
      tipo: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
      createdBy: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      }
    })
    const clearFilter1 = () => {
      filters1.value = {
        fullname: {
          operator: FilterOperator.AND,
          constraints: [
            {
              value: null,
              matchMode: FilterMatchMode.STARTS_WITH
            }
          ]
        },
        createdBy: {
          value: null,
          matchMode: FilterMatchMode.EQUALS
        },
        id: {
          value: null,
          matchMode: FilterMatchMode.EQUALS
        }
      }
    }
    const openModal = (id) => {
      adjuntos.value = []
      idSolicitud.value = id
      solicitudesStore.dispatch('findSolicitud', id).then(() => {
        adjuntos.value = solicitud.value.paths
        displayModal.value = true
      })
    }
    const closeModal = () => {
      displayModal.value = false
    }

    const ver = ({ tipo, base, id }) => {
      console.log(id, 'id ')
      if (tipo === 1) {
        router.push({ name: 'pharmasan.administrativa.juridica.tutelas.ver', params: { id: base } })
      } else if (tipo === 2) {
        router.push({ name: 'procesos.judiciales.incidencias.ver', params: { id: base } })
      } else if (tipo === 3) {
        router.push({ name: 'procesos.judiciales.fallos.ver', params: { id: base } })
      } else if (tipo === 4) {
        router.push({ name: 'procesos.judiciales.respuestas.ver', params: { id: base } })
      } else if (tipo === 5) {
        derechosPeticionStore.dispatch('find', id).then(() => {
          setTimeout(() => {
            router.push({ name: 'pharmasan.administrativa.juridica.procesos-administrativos.ver', params: { id: base } })
          }, 500)
        })
      } else {
        Swal.fire('Error', 'No existe este tipo', 'error')
      }
    }

    const respuesta = (id) => {
      router.push({ name: 'pharmasan.administrativa.juridica.solicitudes.responder', params: { id: id } })
    }

    const verPdf = (path) => {
      _PdfService.ver({ path })
    }

    const indicadorEstadoColor = (fechaVencimiento, respuesta = false) => {
      const inicio = dayjs()
      const fin = dayjs(`${fechaVencimiento}`)
      if (respuesta) return 'respondida'
      if (fin.diff(inicio, 'day') >= 1) {
        return 'atiempo'
      } else if (fin.diff(inicio, 'day') < 1) {
        if (fin.diff(inicio, 'second') > 1) {
          return 'proximo-vencer'
        } else {
          return 'vencidos'
        }
      }
    }

    const onPage = ({ first, page, pageCount, rows }) => {
      // paginator.value.offset = first
      // paginator.value.page = page
      // solicitudesStore.dispatch('getPaginate', {
      //   paginator: paginator.value,
      //   filters: filters1.value
      // })
      limit.value = rows
      solicitudesStore.dispatch('getPaginate', params.value)
    }

    const remove = ({ id }) => {
      Swal.fire({
        title: 'Esta seguro?',
        text: 'Si se elimina esta solicitud no se podrá recuperar',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!'
      }).then((result) => {
        if (result.isConfirmed) {
          solicitudesStore.dispatch('removeSolicitud', id).then(async () => {
            await solicitudesStore.dispatch('getPaginate', params.value)
            Swal.fire(
              'Eliminado!',
              'El juzgado fue eliminado con exito.',
              'success'
            )
          })
        }
      })
    }

    // watch(filters1, (a, b) => {
    //   paginator.value.offset = 0
    //   paginator.value.page = 0
    //   paginator.value.limit = 10
    //   solicitudesStore.dispatch('getPaginate', {
    //     paginator: paginator.value,
    //     filters: filters1.value
    //   })
    // })
    const indicadorEstadoNombre = (fechaVencimiento, respuesta = false) => {
      const inicio = dayjs()
      const fin = dayjs(`${fechaVencimiento}`)
      if (respuesta) return 'Respondida'
      if (fin.diff(inicio, 'day') >= 1) {
        return 'A tiempo'
      } else if (fin.diff(inicio, 'day') < 1) {
        if (fin.diff(inicio, 'second') > 1) {
          return 'A vencer'
        } else {
          return 'Vencido'
        }
      }
    }

    const addSoporte = () => {
      displayModal.value = false
      modalAgregarSoportesVisible.value = true
    }

    const closeAgregarSoportes = () => {
      pond.value.onSubmitSupport().then(async () => {
        paths.value.push({
          urlPath: adjunto.value.urlPath,
          fileName: title.value
        })
        await _PdfService.create({
          tipoNombre: 'solicitudesRespuesta',
          tipo: 7,
          base: idSolicitud.value,
          fileName: paths.value[0].fileName,
          urlPath: paths.value[0].urlPath
        })
        await solicitudesStore.dispatch('getPaginate', params.value)
        modalAgregarSoportesVisible.value = false
        title.value = ''
      })
    }

    watch(filters1, (a, b) => {
      offset.value = 0
      page.value = 0
      limit.value = 10
      solicitudesStore.dispatch('getPaginate', params.value)
    })

    onMounted(async () => {
      if (route.params.id) filters1.value.id.value = route.params.id
      await solicitudesStore.dispatch('getPaginate', params.value)
      await _UserService.getAll().then(({ data }) => {
        usuarios.value = data
      })
    })

    return {
      closeAgregarSoportes,
      solicitudes,
      filters1,
      paginator,
      clearFilter1,
      onPage,
      remove,
      verPdf,
      usuarios,
      ver,
      tipoSolicitudes,
      offset,
      limit,
      params,
      totalItemsCount,
      openModal,
      closeModal,
      displayModal,
      adjuntos,
      respuesta,
      indicadorEstadoColor,
      indicadorEstadoNombre,
      dayjs,
      addSoporte,
      modalAgregarSoportesVisible,
      title,
      pond
    }
  }
}
</script>

<style scoped>
::v-deep(.pi) {
  font-size: 0.8rem;
}

::v-deep(.p-column-header-content) {
  justify-content: center;
}

.respondida {
  background-color: #1149d7 !important;
  color: white;
}

.atiempo {
  background-color: #58AB30 !important;
  color: white;
}

.proximo-vencer {
  background-color: #FA901B !important;
}

.vencidos {
  background-color: #FA2311 !important;
}
</style>
